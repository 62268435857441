import React from 'react'
import { PortOfVancouverView } from '@views'

import { graphql } from 'gatsby'

import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Port of Vancouver'} />
}

const PortOfVancouver = (props) => {
  const data = props?.data?.contentfulCaseStudyGrid

  return <PortOfVancouverView data={data} />
}

export const projectQuery = graphql`
  query projectQuery {
    contentfulCaseStudyGrid(page: { eq: "Projects" }) {
      ...CaseStudyGrid
    }
  }
`

export default PortOfVancouver
